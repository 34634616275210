<template>
    <router-link
        :to="{
            name: 'legalDetail',
            query: {
                id: info.id
            }
        }"
        class="patent"
    >
        <el-image :src="getImg(info.img)" fit="cover"></el-image>

        <div class="content">
            <div class="title">{{ info.name }}</div>
            <div class="desc">{{ info.summary }}</div>
            <div class="time">{{ info.issuedAt }}</div>
        </div>

        <!-- <div class="btn">
            <el-button size="small"> 详情<i class="el-icon-arrow-right el-icon--right"></i> </el-button>
        </div> -->
    </router-link>
</template>

<script>
import comEvent from '../../mixins/comEvent';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    mixins: [comEvent]
};
</script>

<style lang="less" scoped>
.patent {
    padding: 10px 20px;
    .flex();

    .el-image {
        width: 138px;
        height: 98px;
        flex-shrink: 0;
    }
    position: relative;
    cursor: pointer;
    border: 1px solid transparent;
    border-bottom-color: @bg;

    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(255, 135, 0, 0.2);
        border: 1px solid @prim;
        .content {
            .title {
                color: @prim;
            }
            .desc {
                color: fade(@prim, 66%);
            }
            .time {
                color: fade(@prim, 66%);
            }
        }
    }
    overflow: hidden;
}

.content {
    padding: 0 80px 0 16px;
    flex-grow: 1;
    height: 98px;
    .flex-col();
    overflow: hidden;
    .title {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        line-height: 20px;
        .ellipsis();
        padding-bottom: 12px;
    }

    .desc {
        font-size: 13px;
        color: #939599;
        line-height: 18px;
        .ellipsis-line(2);
    }

    .time {
        font-size: 14px;
        color: #939599;
        line-height: 20px;
        padding-top: 10px;
    }
}
</style>
